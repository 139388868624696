import { Observable } from 'rxjs';
import Events from '../_util/events';
import { SocketService } from './socket.service';
import { AccessLevel } from '../_enum/IAccessLevel';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PermissionsUpdateService } from './permission.update.service';
import * as i0 from "@angular/core";
import * as i1 from "./socket.service";
import * as i2 from "./permission.update.service";
export class PermissionService {
    constructor(socketService, permissionsUpdateService) {
        this.socketService = socketService;
        this.permissionsUpdateService = permissionsUpdateService;
        this.myPermissions = [];
        this.permissions = [];
        this.entitlements = [];
    }
    init() {
        return new Observable((observer) => {
            // nested observables - slightly hacky
            this.socketService
                .call(Events.GET_PERMISSIONS, (myPermissions) => {
                this.myPermissions = myPermissions;
                this.permissionsUpdateService.notifyNetworkGroupsUpdate();
            })
                .subscribe((_) => {
                this.socketService
                    .call(Events.LIST_PERMISSION_TYPES, (permissions) => {
                    this.permissions = permissions;
                    this.permissionsUpdateService.notifyNetworkGroupsUpdate();
                })
                    .subscribe((__) => {
                    this.socketService
                        .call(Events.LIST_ENTITLEMENTS, (entitlements) => {
                        this.entitlements = entitlements;
                        this.permissionsUpdateService.notifyNetworkGroupsUpdate();
                    }).subscribe((_) => {
                        observer.next();
                        observer.complete();
                    });
                });
            });
        });
    }
    isFirstTimeUser(user) {
        // Fetch payment methods asynchronously
        return this.socketService.call(Events.LIST_ENTITLEMENTS, undefined, user).pipe(map((paymentMethods) => {
            return paymentMethods && paymentMethods.length === 0; // First-time user if no payment methods
        }), catchError((error) => {
            console.error('Error fetching payment methods:', error);
            return of(false);
        }));
    }
    isFirstTimeUserToPromise(user) {
        return this.isFirstTimeUser(user).toPromise(); // Convert the observable to a promise
    }
    getMyPermissions() {
        return this.myPermissions;
    }
    getPermissions() {
        return this.permissions;
    }
    getEntitlements() {
        return this.entitlements;
    }
    getEntitlementsForResource(resource) {
        return this.entitlements.filter(entitlement => entitlement.target.resource === resource._id);
    }
    getEntitlementsForUser(userId) {
        return this.entitlements.filter(entitlement => entitlement.user === userId);
    }
    getEvaluatedEntitlementsForUser(userId) {
        return this.socketService
            .call(Events.GET_EVALUATED_ENTITLEMENT_ACCESS, () => {
            this.permissionsUpdateService.notifyNetworkGroupsUpdate();
        }, userId);
    }
    createEntitlement(resource, resourceType, userId, accessLevel) {
        return this.socketService
            .call(Events.CREATE_ENTITLEMENT, (entitlement) => {
            this.entitlements.push(entitlement);
            this.permissionsUpdateService.notifyNetworkGroupsUpdate();
        }, resource, resourceType, userId, accessLevel);
    }
    editEntitlement(entitlement, config) {
        return this.socketService
            .call(Events.EDIT_ENTITLEMENT, (editedEntitlement) => {
            Object.assign(entitlement, editedEntitlement);
            this.permissionsUpdateService.notifyNetworkGroupsUpdate();
        }, entitlement._id, config);
    }
    deleteEntitlement(entitlement) {
        return this.socketService
            .call(Events.DELETE_ENTITLEMENT, (entitlement) => {
            this.entitlements = this.entitlements.filter((innerEntitlement) => innerEntitlement._id !== entitlement._id);
            this.permissionsUpdateService.notifyNetworkGroupsUpdate();
        }, entitlement._id);
    }
    hasAccessLevelOnResource(resource, accessLevel) {
        if (!resource['@permission']) {
            return undefined;
        }
        const currentAccessLevel = resource['@permission'];
        switch (accessLevel) {
            case AccessLevel.OWNER:
                return currentAccessLevel === AccessLevel.OWNER;
            case AccessLevel.EDITOR:
                return currentAccessLevel === AccessLevel.OWNER || currentAccessLevel === AccessLevel.EDITOR;
            case AccessLevel.VIEWER:
                return currentAccessLevel === AccessLevel.OWNER || currentAccessLevel === AccessLevel.EDITOR || currentAccessLevel === AccessLevel.VIEWER;
        }
        return false;
    }
    isAdmin(userType) {
        return (userType === 'admin' || userType === 'internal');
    }
    checkForPermission(userType, permissionType) {
        return true;
        if (this.isAdmin(userType)) {
            console.log('cc-trace-9cde-6bc64d34f0c1:', 'user is admin');
            return true;
        }
        return !!this.myPermissions.find((permission) => permission.type === permissionType);
    }
    getPermissionById(id) {
        return this.permissions.find((permission) => permission._id === id);
    }
}
PermissionService.ngInjectableDef = i0.defineInjectable({ factory: function PermissionService_Factory() { return new PermissionService(i0.inject(i1.SocketService), i0.inject(i2.PermissionsUpdateService)); }, token: PermissionService, providedIn: "root" });
