import { SuiModal } from 'ng2-semantic-ui';
import { Component } from '@angular/core';
import IResourceModalContext from './resourceView.interface';

@Component({
	selector: 'app-modal-resource-review',
	templateUrl: './resourceView.modal.html',
	styleUrls: ['./resourceView.modal.scss'],
})
export class ResourceViewModalComponent {
	public resource: any;

	constructor(public modal: SuiModal<IResourceModalContext, void, void>) {
		this.resource = this.modal.context.resource;
	}

	public isVideo(): boolean {
		return this.resource.mimeType.startsWith('video');
	}

	public isImage(): boolean {
		return this.resource.mimeType.startsWith('image');
	}

	public isAudio(): boolean {
		return this.resource.mimeType.startsWith('audio');
	}

	public isPDF(): boolean {
		return this.resource.mimeType === 'application/pdf';
	}

	public canPreview(): boolean {
		return this.isVideo() || this.isImage() || this.isAudio() || this.isPDF();
	}
}
