import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import IUser from 'src/_interfaces/IUser';
import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import ISession from '@citadel/common-frontend/_interfaces/ISession';
import { SocketService } from '@citadel/common-frontend/_services/socket.service';
import Events from '../_util/events';
import { environment } from '../environments/environment';
import { LoggedInUserUpdateService } from './logged-in-user-update.service';

export const SESSION_ID = 'ads_session';

@Injectable()
export class UserService {
	private user: IUser;
	private session: any;
	private isLoggingOut = false;

	constructor(private router: Router,
		private socketService: SocketService,
		private cookies: CookieService,
		private loggedInUserUpdates: LoggedInUserUpdateService
	) { }

	public init(authenticated: boolean): Observable<any> {
		if (authenticated) {
			const cookie = this.getSessionCookie();

			if (!cookie) {
				throw new Error('invalid session');
			}

			this.session = JSON.parse(atob(cookie));
			this.socketService.getSocketConnection().on('reconnect', async () => {
				await this.sendToken().toPromise();
			});

			return this.sendToken();
		}
		return new Observable((observer) => observer.complete());
	}

	public sendToken(): Observable<IUser> {
		return this.socketService.call(
			Events.AUTHENTICATE_TOKEN_EVENT,
			async (user: IUser) => {
				this.user = user;
				this.loggedInUserUpdates.notifyLoggedInUserUpdate();
			},
			this.session.token
		);
	}

	public login(config: any): Observable<ISession<IUser>> {
		return this.socketService.call(
			Events.LOGIN,
			async (response: any) => {
				const session = response.data as ISession<IUser>;
				this.user = session.user;
				this.setSession(session);
				this.loggedInUserUpdates.notifyLoggedInUserUpdate();
				this.router.navigate(['/dashboard']);
			},
			config
		);
	}

	logout() {
		this.clearSession();

		if (this.isLoggingOut) {
			return;
		}

		this.isLoggingOut = true;

		setTimeout(() => {
			// this.router.navigate(['/authentication/login']);
      this.router.navigateByUrl('/authentication/login');
      setTimeout(() => {
          window.location.reload(false);
        window.location.href = '/authentication/login';
      }, 1500);
    }, 100);
	}

	public getUser(): IUser {
		return this.user;
	}

	public isSessionValidSync(): boolean {
		const cookie = this.getSessionCookie();
		if (!cookie) {
			return false; // No session cookie
		}
		try {
			const session = JSON.parse(atob(cookie)); // Decode and parse
			return !!(session && session.token); // Check if token exists
		} catch (err) {
			console.error('cc-trace-909f-c43acdedcf48', 'Invalid session format:', err);
			return false;
		}
	}

	public setSession(session: ISession<IUser>): void {
		this.session = session;
		// tslint:disable-next-line:max-line-length
		this.cookies.set(SESSION_ID, btoa(JSON.stringify(session)), new Date(session.expires), '/', environment.cookie.url, environment.cookie.secure, <any>environment.cookie.sameSite);
	}

	public loginWithSession(sessionString: string) {
		if (!sessionString) {
			throw new Error('invalid session');
		}
		const session = JSON.parse(atob(sessionString));
		this.user = session.user;
		this.setSession(session);
		this.router.navigate(['/dashboard']);
	}

	public clearSession(): void {
		this.user = null;
		this.session = null;
		this.loggedInUserUpdates.notifyLoggedInUserUpdate();
		this.cookies.deleteAll('/', environment.cookie.url);
	}

	public getSession(): ISession<IUser> {
		return this.session;
	}

	public getSessionCookie(): string {
		return this.cookies.get(SESSION_ID);
	}
}
