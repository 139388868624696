import { SuiModal } from 'ng2-semantic-ui';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';

import { FilesystemService } from '../../_services/filesystem.service';
import { LoaderService } from '../../_services/loader.service';
import { NotificationService } from '../../_services/notification.service';
import IResourceModalContext from './resource.interface';
// import {UserService} from '@citadel/ads-frontend/src/_services/user.service';

@Component({
	selector: 'app-modal-resource',
	templateUrl: './resource.modal.html',
	styleUrls: ['./resource.modal.scss'],
})
export class ResourceModalComponent {
	@ViewChild('uploadInput')
	public uploadInput: ElementRef;
	public resource: any;
	public type: string;
	public resourceFormGroup: FormGroup;
	public justUploaded: boolean;
	public currentProgress: number;
  public availableTags: any[] = [];

	constructor(
		public modal: SuiModal<IResourceModalContext, void, void>,
		private notificationService: NotificationService,
		private filesystemService: FilesystemService,
		private loaderService: LoaderService,
		private fb: FormBuilder,
		// private userService: UserService
	) {
		this.resource = this.modal.context.resource;
		this.justUploaded = this.modal.context.justUploaded;
    this.availableTags = this.modal.context.availableTags;

		if (this.resource['path']) {
			this.type = 'directory';
		} else {
			this.type = 'file';
		}
		this.currentProgress = 0;
		this.initializeForm();
	}

	get uploadedPDF() {
		return this.resourceFormGroup['controls'].meta['controls'].find((item) => item.value.key === 'pdfId' && item.value);
	}

	get metadataFormControls() {
		return this.resourceFormGroup['controls'].meta['controls'].filter((item) => item.value.key !== 'pdfId');
	}

	get canUploadPDF() {
		return this.resource.type !== 'system';
	}

	private initializeForm() {
		this.resourceFormGroup = this.fb.group({
			name: [this.resource.name, Validators.compose([Validators.required])],
      tags: [this.mapAvailableTags(), Validators.compose([])],
			meta: this.fb.array([]),
		});
		if (this.resource.meta) {
			for (const metadata of this.resource.meta) {
				this.initializeMetadata(metadata);
			}
		}
	}

  private mapAvailableTags(): any[] {
    if (!this.resource.tags || !this.availableTags || this.availableTags.length === 0) {
      return [];
    }
    return this.resource.tags.map(tagId => this.availableTags.find(innerTag => innerTag._id === tagId)).filter(tag => tag);
  }

	public async deleteResource() {
		this.loaderService.setLoading(true, 'Updating resource');
		if (this.type === 'file') {
			const file = this.modal.context.resource;
			await this.filesystemService.delete(this.type, file).toPromise();
			this.loaderService.setLoading(false);
			this.notificationService.displaySuccess('Successfully deleted video: ' + file.name);
		}
	}

	public getSessionToken(): string {
		// TODO: fix this by injecting the session token into the modal
		throw new Error('Cannot fetch session token');
	}

	private async uploadFile(file: File) {
		const { body: uploadedFile } = await this.filesystemService.upload(file, this.resource, this.getSessionToken()).toPromise();
		this.loaderService.setLoading(true, 'Uploading PDF');
		await this.initializeMetadata({
			key: 'pdfId',
			value: uploadedFile._id,
			name: `${uploadedFile.name}.${uploadedFile.extension}`,
			required: false,
			readOnly: true,
		});
		const resourceFormGroup = this.resourceFormGroup.value;
		await this.filesystemService.edit(this.type, this.resource, resourceFormGroup).toPromise();
		this.loaderService.setLoading(false);
	}

	public upload() {
		if (this.uploadInput) {
			this.uploadInput.nativeElement.click();
		}
	}

	public onFileChange(e) {
		if (!e.target.files[0]) {
			return;
		}

		const file = e.target.files[0];

		const blob = file.slice(0, file.size, file.type);
		const newFile = new File([blob], Date.now() + '-' + file.name, { type: file.type });

		this.uploadFile(newFile);
		e.target.value = '';
	}

	public async downloadAttachedPdf() {
		const item = this.resourceFormGroup.controls['meta'].value.find((innerItem) => innerItem.key === 'pdfId' && innerItem.value);

		if (!item.value) {
			return;
		}

		const fileDetails = await this.filesystemService.getFileDetails(item.value).toPromise();

		if (fileDetails.cdnUrl) {
			const link = document.createElement('a');
			link.href = fileDetails.cdnUrl;
			link.download = fileDetails.name;
			link.target = '_blank';
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	}

	public async deleteAttachedPdf() {
		this.loaderService.setLoading(true, 'Deleting PDF');

		const resourceFormGroup = this.resourceFormGroup.value;

		await this.filesystemService
			.edit(this.type, this.resource, {
				name: resourceFormGroup.name,
				meta: resourceFormGroup.meta.filter((item) => item.key !== 'pdfId'),
			})
			.toPromise();

		this.loaderService.setLoading(false);

		const formArray = <FormArray>this.resourceFormGroup.controls['meta'];
		formArray.removeAt(formArray.controls.findIndex((item) => item.value.key === 'pdfId'));
	}

	private initializeMetadata(metadata: any) {
		const formArray = <FormArray>this.resourceFormGroup.controls['meta'];

		const fbGroup = this.fb.group({
			key: [metadata.key, Validators.required],
			value: [metadata.value, []],
			name: [metadata.name, []],
			required: [metadata.required, []],
			readOnly: [metadata.readOnly, []],
		});

		formArray.push(fbGroup);

		return fbGroup;
	}

	public async saveChanges() {
		this.loaderService.setLoading(true, 'Updating resource');

		try {
			await this.filesystemService.edit(this.type, this.resource, this.resourceFormGroup.value).toPromise();
			this.modal.approve(undefined);
		} catch (e) {
			this.notificationService.displayError(e);
		}
		this.loaderService.setLoading(false);
	}
}
